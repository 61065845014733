<template>
    <b-overlay>
        <b-card :title="title">
            <b-container fluid>
                <b-row align-h="between" class="mb-2">
                    <b-col class="float-left pl-0" cols="2">
                        <b-button variant="primary" class="ml-0" :disabled="addButtonsDisabled"
                                  @click="addOrderModal = true">Dodaj
                            naročilo
                        </b-button>
                    </b-col>
                    <b-col cols="3" @click="editPlanAreaModal = !buttonsDisabled">
                        <b-progress height="3rem" class="w-100" show-progress show-value :max="plan.planned_amount">
                            <b-progress-bar :value="getCurrentOrdersArea" :striped="true">
                                <span> št. naročil: <strong>{{
                                    plan.orders.length
                                }} | </strong>m²: <strong>{{ getCurrentOrdersArea.toFixed(2) }} / {{
                                    plan.planned_amount
                                }}</strong>
                                </span>
                            </b-progress-bar>
                        </b-progress>
                    </b-col>
                    <b-col class="float-right" cols="7">
                        <b-row align-h="end">
                            <b-button variant="info" class="ml-0" @click="commentModal = true">Komentar</b-button>
                            <b-button variant="primary" class="ml-1" :disabled="buttonsDisabled"
                                      @click="optimimizePlan">Optimiziraj
                            </b-button>
                            <!-- <b-button class="mx-1" variant="success">Potrdi</b-button> -->
                            <b-button variant="warning" class="ml-1" :disabled="addButtonsDisabled"
                                      @click="suggestOrdersModal = true">
                                Predlagaj naročila
                            </b-button>
                            <b-button variant="primary"  class="ml-1" @click="lockPlan" v-if="planUnlocked">Zakleni plan</b-button>
                            <b-button variant="success" class="ml-1" @click="unlockPlanWithSave"   v-else-if="planLockedByThisUser">Shrani in odkleni plan</b-button>
                            <b-button variant="danger" class="ml-1" @click="showForceUnlockPlanModal = true" v-else-if="planLockedByOtherUser">Prisilno odkleni plan</b-button>
                            <b-button variant="primary" class="ml-1" disabled v-else>Nalagam...</b-button>
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>
            <b-row>
                <b-button class="mx-1" variant="danger" :href="`/api/v1/user/plan/${$route.params.planId}/pdf`">Izvozi
                    PDF
                    naročil
                </b-button>
                <b-button class="mx-1" variant="success" :href="`/api/v1/user/plan/${$route.params.planId}/excel`">
                    Izvozi Excel
                    naročil
                </b-button>
                <b-button class="mx-1 ml-1" variant="danger"
                          :href="`/api/v1/user/plan/${$route.params.planId}/pdf_cut`">Izvozi
                    PDF razreza
                </b-button>
                <b-button class="mx-1 ml-1" variant="danger"
                          :href="`/api/v1/user/plan/${$route.params.planId}/pdf_additional`">Izvozi
                    PDF dodatnega materiala
                </b-button>
                <b-button variant="success" class="ml-1" :disabled="buttonsDisabled" @click="showConfirmPlanModal = true">Potrdi plan
                </b-button>
            </b-row>
            <div>
                <b-row class="float-right col-sm-2 mb-1">
                    <b-form-input type="search" v-model="search" placeholder="Išči..." size="sm"/>
                </b-row>
                <b-table small striped responsive="sm" bordered hover :items="filtered" :fields="fields"
                         :filter="search"
                         :per-page="perPage" :current-page="currentPage">
                    <template slot="top-row" slot-scope="{ fields }">
                        <b-td v-for="field in fields" :key="field.key">
                            <b-form-input v-if="field.key !== 'actions'" size="sm" v-model="filters[field.key]"
                                          :placeholder="field.label"/>
                        </b-td>
                    </template>
                    <template v-slot:cell(status)="row">
                        <b-badge v-if="row.item.status === 'izveden'" variant="success">{{ row.item.status }}</b-badge>
                        <b-badge v-else-if="row.item.status === 'v planu'" variant="warning">{{
                            row.item.status
                        }}
                        </b-badge>
                        <b-badge v-else variant="danger">{{ row.item.status }}</b-badge>
                    </template>
                    <template v-slot:cell(type)="row">
                        <b-badge v-if="row.item.type === 'generiran'" variant="info">{{ row.item.type }}</b-badge>
                        <b-badge v-else>{{ row.item.type }}</b-badge>
                    </template>
                    <template v-slot:cell(date_due)="row">
                        {{ new Date(row.item.date_due).toLocaleDateString("sl") }}
                    </template>
                    <template v-slot:cell(area)="row">
                        <div v-if="row.item.order_pieces !== undefined">
                            {{
                                (row.item.order_pieces.reduce((acc, cur) => acc + (cur.quantity * cur.length * cur.width), 0)) / 1000000
                            }}m²
                        </div>
                        <div v-else>0m²</div>

                    </template>
                    <template #cell(actions)="row">
                        <div class="d-flex justify-content-center">
                            <b-button v-if="row.item.comment_for_razrez == undefined" class="mr-1"
                                      @click="$refs.orderModal.open(row.item)" variant="success">
                                <fa icon="eye"/>
                            </b-button>
                            <b-button v-else class="mr-1" @click="$refs.orderModal.open(row.item)" variant="warning">
                                <fa icon="eye"/>
                            </b-button>
                            <b-button class="mr-1" :disabled="buttonsDisabled || cannotModifyOrder(row.item.id)"
                                      @click="removeOrder(row.item.id)" variant="danger">
                                <fa icon="trash"/>
                            </b-button>
                            <b-button class="mr-1" :disabled="buttonsDisabled || cannotModifyOrder(row.item.id)"
                                      @click="moveOrderToOtherPlanModal(row.item.id)"
                                      variant="primary">
                                <fa icon="exchange-alt"/>
                            </b-button>
                            <b-button @click="selected_order = row.item; orderNoteModal = true" class="mr-1"
                                      :disabled="buttonsDisabled"
                                      variant="info">
                                <fa icon="pencil-alt"/>
                            </b-button>
                            <b-button @click=" selected_order = row.item; orderCommentModal = true"
                                      :disabled="buttonsDisabled"
                                      variant="primary">
                                <fa icon="comment"/>
                            </b-button>

                        </div>
                    </template>
                </b-table>
                <b-row>
                    <b-col class="float-left">
                        <b-dropdown variant="outline" :text="'Na stran: ' + perPage" size="sm" class="btn-none">
                            <b-dropdown-item v-model="perPage" v-for="(item, key) in pageOptions" :key="key"
                                             @click="setPerPage(item)">
                                {{ item }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col sm="7" md="6" class="float-right">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
                                      size="sm"/>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <b-card v-for="(planRazreza, indexRazreza) in plan.material_plans" :key="indexRazreza">
            <b-card-title>
                <b-row>
                    <b-col cols="6">{{ planRazreza.material_type.material }}
                        {{ planRazreza.status === 'Confirmed' ? '- POTRJEN' : '' }}
                    </b-col>
                    <b-col cols="6" class="float-right">
                        <b-row class="float-right" align-v="center">
                            <b-btn class="float-right mr-1" @click="planToConfirm = planRazreza.material_type.id; showConfirmMaterialPlanModal = true"
                                   variant="success" :disabled="planRazreza.status === 'Confirmed' || buttonsDisabled">Potrdi
                            </b-btn>
                            <b-button class="mr-1" variant="info"
                                      :href="`/api/v1/user/plan/${$route.params.planId}/pdf_cut/${planRazreza.material_type.id}`">Izvozi
                                PDF razreza za material
                            </b-button>
                            <b-btn class="float-right mr-1"
                                   @click="selectedMaterialPlan = indexRazreza; materialPlanConfirmationDetails = true; updateMaterialChoices()"
                                   variant="primary"><span
                                       v-if="planRazreza.status === 'Confirmed' || !planLockedByThisUser">Specifikacije</span><span v-else>Uredi specifikacije</span>
                            </b-btn>
                            <b-check v-model="plan.material_plans[indexRazreza].locked" @change="savePlan"
                                     class="float-right mr-1"
                                     :disabled="planRazreza.status === 'Confirmed' || !planLockedByThisUser">Zaklenjen
                            </b-check>
                        </b-row>

                    </b-col>
                </b-row>
            </b-card-title>

            <b-table-simple responsive class="text-center" small striped hover>
                <b-thead>
                    <b-tr>
                        <b-th @click="sort" rowspan="2">Dolžina kosa za razrez</b-th>
                        <b-th rowspan="2">Št. ponovitev</b-th>
                        <b-th rowspan="2">P-številka</b-th>
                        <b-th colspan="3">Razrez</b-th>
                    </b-tr>
                    <b-tr>
                        <b-th>Naročilo</b-th>
                        <b-th>Dolžine</b-th>
                        <b-th>Količina</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(item,index) in planRazreza.stock_with_cuts_deduped" :key="index">
                        <b-td>{{ item.stock_piece.length }}</b-td>
                        <b-td>{{ item.deduped_quantity }}</b-td>
                        <b-td>{{ item.p_number }}</b-td>
                        <b-td colspan="3" class="tabela">
                            <b-table-simple class="text-left">
                                <b-tr v-for="(items, itemIndex) in item.order_pieces" :key="itemIndex"
                                      style="background-color:rgba(0,0,0,0)">
                                    <b-td>{{ items.order_id }}</b-td>
                                    <b-td>{{ items.length }}</b-td>
                                    <b-td>{{ items.quantity }}</b-td>
                                </b-tr>
                            </b-table-simple>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-card>

        <b-modal size="xl" title="Dodaj naročilo" v-model="addOrderModal" centered hide-footer>
            <NarocilaSelectable :orders="orders"></NarocilaSelectable>
            <b-button class="float-right mt-1" variant="primary" @click="addOrder()">Dodaj</b-button>
        </b-modal>

        <b-modal size="m" title="m² v planu" v-model="editPlanAreaModal" centered hide-footer>
            <b-form-input v-model="plan.planned_amount" type="number" size="sm"/>
            <b-button class="float-right mt-1" variant="primary" @click="savePlan()">Shrani</b-button>
        </b-modal>

        <b-modal size="m" title="Premikanje naročila" v-model="orderMoveModal" centered hide-footer>
            <b-form-select :options="otherPlans" label="Plan za premik" size="sm" v-model="selectedOtherPlanRaw"
                           @change=" it => this.selectedOtherPlan = it.id"></b-form-select>
            <b-button class="float-left mt-1" variant="info" @click="moveOrderToWaitingList()">
                Premakni na čakalno listo
            </b-button>
            <b-button class="float-left mt-1 ml-1" variant="primary" @click="moveOrderToOtherPlan(selectedOtherPlan)">
                Premakni
            </b-button>
            <b-button class="float-right mt-1" variant="danger" @click="orderMoveModal = false">Prekliči</b-button>
        </b-modal>


        <b-modal size="m" title="Komentar" v-model="commentModal" centered hide-footer>
            <b-form-input v-model="plan.comment" size="m"/>
            <b-button class="float-right mt-1" variant="primary" @click="savePlan()">Shrani</b-button>
        </b-modal>

        <b-modal size="s" v-model="loadingCombined" centered hide-footer no-close-on-backdrop no-close-on-esc
                 hide-header
                 hide-header-close>
            <div class="d-flex justify-content-center">
                <b-spinner style="height: 10rem;width: 10rem"></b-spinner>
            </div>
        </b-modal>

        <b-modal size="m" v-model="orderCommentModal" title="Komentar naročila" centered hide-footer>
            <b-form-input v-model="selected_order.comment_from_razrez" size="m"/>
            <b-button class="float-right mt-1" variant="primary" @click="saveOrderComment()">Shrani
            </b-button>
        </b-modal>

        <b-modal size="m" v-model="orderNoteModal" title="Opomba naročila" centered hide-footer>
            <b-form-input v-model="selected_order.comment" size="m"/>
            <b-button class="float-right mt-1" variant="primary" @click="savePlan(); orderNoteModal = false">Shrani
            </b-button>
        </b-modal>

        <OrderModal button-active ref="orderModal" :material-options="materialOptionsAll"
                    :change-material="changeMaterial"/>


        <b-modal size="m" v-model="suggestOrdersModal" title="Predlaganje naročil" centered hide-footer>
            <b-form-group label="Prisilno vključi naročila, ki imajo rok razreza največ toliko dni od danes:">
                <b-form-input v-model="suggestedOrderDaysMin" type="number" size="m"/>
            </b-form-group>
            <b-form-group label="Omogoči vključitev naročil, ki imajo rok razreza največ toliko dni od danes:">
                <b-form-input v-model="suggestedOrderDays" type="number" size="m"/>
            </b-form-group>
            <b-button class="float-right mt-1" variant="primary" @click="suggestOrders()">Predlagaj naročila</b-button>
        </b-modal>


        <b-modal size="m" v-model="showConfirmPlanModal" title="Potrdi plan" centered hide-footer>
            <h4>Potrdi plan {{title}} ?</h4>
            <div v-if="plan.material_plans.filter( it => it.status !== 'Confirmed').length > 0">
                <h4>Nepotrjeni materiali:</h4>
                <ul>
                    <li v-for="unconfirmedPlan in plan.material_plans.filter( it => it.status !== 'Confirmed')" :key="unconfirmedPlan.material_type.id">{{unconfirmedPlan.material_type.id}}</li>
                </ul>
            </div>
            <b-button class="float-right mt-1" variant="success" @click="confirmPlan()">Potrdi plan</b-button>
        </b-modal>

        <b-modal size="m" v-model="showConfirmMaterialPlanModal" title="Potrdi material" centered hide-footer>
            <h4>Potrdi plan za material {{planToConfirm}} ?</h4>
            <b-button class="float-right mt-1" variant="success" @click="confirmMaterialPlan(planToConfirm)">Potrdi material</b-button>
            <b-button class="float-right mt-1" variant="danger" v-if="$store.state.user.userData.name === 'Admin'" @click="confirmMaterialPlanAdmin(planToConfirm)">Potrdi material Admin</b-button>
        </b-modal>

        <b-modal size="xl" v-model="materialPlanConfirmationDetails" title="Urejanje specifikacije" centered
                 hide-footer>
            <b-container fluid v-if="plan.material_plans[selectedMaterialPlan] !== undefined">
                <b-button
                    style="margin : 0.2rem"
                    @click="plan.material_plans[selectedMaterialPlan].stock_with_cuts_deduped === null ? plan.material_plans[selectedMaterialPlan].stock_with_cuts_deduped = [{stock_piece : {}, deduped_quantity: 1, order_pieces: [], left_over_length: 0, p_number: ''}] : plan.material_plans[selectedMaterialPlan].stock_with_cuts_deduped.push({stock_piece : {}, deduped_quantity: 1, order_pieces: [], left_over_length: 0, p_number: ''})"
                    variant="success">
                    Dodaj nov vhodni kos
                </b-button>
                <b-row align-h="center" style="margin: 0.2rem">

                    <b-table :items="plan.material_plans[selectedMaterialPlan].stock_with_cuts_deduped" bordered small
                             striped
                             responsive
                             :fields="[
                                 {key: 'stock_piece', label: 'Vhodni kos', sortable: false, class: 'text-center'},
                                 {key: 'order_pieces', label: 'Izhodni kosi', sortable: false, class: 'text-center'},
                                 {key: 'left_over_length', label: 'Dolžina ostanka (mm)', sortable: false, class: 'text-center'},
                                 {key: 'actions', label: '', sortable: true, class: 'text-center'}]">

                        <template v-slot:cell(left_over_length)="row">
                            <p :style="getLeftOverStyleForOrderPiecesAndLength(row.item.order_pieces, row.item.stock_piece.length)">
                                {{
                                    getLeftOverForOrderPiecesAndLength(row.item.order_pieces, row.item.stock_piece.length)
                                }}</p>
                        </template>
                        <template v-slot:cell(stock_piece)="row">
                            <b-form-group label="Material:" label-cols="2">
                                <b-form-select v-model="row.item.stock_piece.material_type"
                                               :options="materialOptionsForChange"
                                               size="sm"
                                               v-on:change="it =>  row.item.stock_piece.length = (row.item.stock_piece.length >= it.min_length && row.item.stock_piece.length <= it.max_length) ? row.item.stock_piece.length : it.min_length"/>
                            </b-form-group>
                            <b-form-group id="ponovitve" label="Ponovitve:" label-cols="2">
                                <b-tooltip target="ponovitve">Število ponovitev celotne vrstice</b-tooltip>
                                <b-form-input v-model.number="row.item.deduped_quantity" type="number" size="sm"/>
                            </b-form-group>
                            <b-form-group label="Dolžina:" label-cols="2">
                                <b-form-input v-model.number="row.item.stock_piece.length" type="number" size="sm"
                                              v-on:change="it => row.item.stock_piece.material_type = (row.item.stock_piece.material_type.min_length >= it || row.item.stock_piece.material_type.max_length <= it) ? materialOptionsForChange.find(material => material.value.min_length <= it && material.value.max_length >= it).value: row.item.stock_piece.material_type"/>
                            </b-form-group>
                            <b-form-group label="P-številka:" label-cols="2">
                                <b-form-input v-model="row.item.p_number" size="sm" list="my-list-id" lazy ></b-form-input>
                                <datalist id="my-list-id">
                                    <option v-for="pNumber in new Set(plan.material_plans[selectedMaterialPlan].stock_with_cuts_deduped.map(it => it.p_number))"  :key="pNumber">{{ pNumber }}</option>
                                </datalist>
                            </b-form-group>

                        </template>
                        <template v-slot:cell(order_pieces)="row">
                            <b-table :items="row.item.order_pieces" bordered small striped responsive
                                     :fields="[
                                         {key: 'length', label: 'Dolžina (mm)', sortable: true, class: 'text-center'},
                                         {key: 'quantity', label: 'Število', sortable: false, class: 'text-center'},
                                         {key: 'order_id', label: 'Naročilo', sortable: false, class: 'text-center'},

                                         {key: 'actions', label: '', sortable: false, class: 'text-center'}]">
                                <template v-slot:cell(quantity)="row1">
                                    <b-tooltip target="kolicinaIzhod">Število izhodnih kosov te dolžine iz enega vhodnega kosa</b-tooltip>
                                    <b-form-input id="kolicinaIzhod" v-model.number="row1.item.quantity" type="number" size="sm"/>
                                </template>
                                <template v-slot:cell(length)="row1">
                                    <b-form-input v-if="row1.item.order_id === ''" v-model.number="row1.item.length"
                                                  type="number" size="sm"/>
                                    <b-form-select v-else v-model="row1.item.length"
                                                   :options="plan.material_plans[selectedMaterialPlan].orders.find(order => order.id === row1.item.order_id).order_pieces.map((order_piece) => ({value:order_piece.length, text: order_piece.length }))"

                                                   size="sm"/>
                                </template>
                                <template v-slot:cell(order_id)="row1">

                                    <b-form-select v-model="row1.item.order_id"
                                                   :options="plan.material_plans[selectedMaterialPlan].orders.map((order) => ({value:order.id, text: order.id + ' ('+order.client+')' }))"
                                                   size="sm"/>

                                </template>
                                <template v-slot:cell(actions)="row1">
                                    <div class="d-flex justify-content-center">
                                        <b-button
                                            @click="row.item.order_pieces = row.item.order_pieces.filter(i => i !== row1.item)"
                                            variant="danger">
                                            <fa icon="trash"/>
                                        </b-button>
                                    </div>
                                </template>

                            </b-table>
                        </template>
                        <template v-slot:cell(actions)="row">
                            <div class="d-flex flex-column" style="row-gap: 10px">
                                <b-button
                                    @click="plan.material_plans[selectedMaterialPlan].stock_with_cuts_deduped = plan.material_plans[selectedMaterialPlan].stock_with_cuts_deduped.filter(i => i !== row.item)"
                                    variant="danger">
                                    Izbriši vhodni kos
                                    <fa icon="trash"/>
                                </b-button>
                                <b-button @click="row.item.order_pieces.push({length: 0, quantity: 0, order_id:''})"
                                          variant="success">
                                    Dodaj izhodni kos
                                    <fa icon="plus"/>
                                </b-button>
                                <b-button @click="row.item.stock_piece.deduped_quantity -= 1; plan.material_plans[selectedMaterialPlan].stock_with_cuts_deduped.splice(row.index,0,JSON.parse(JSON.stringify(row.item)))"
                                          variant="info">
                                    Dodaj P-številko
                                    <fa icon="plus"/>
                                </b-button>
                            </div>
                        </template>
                    </b-table>

                    <b-col><h2 v-if="missingOrderPieces.length > 0">Manjkajoči/odvečni izhodni kosi</h2>
                        <p v-if="missingOrderPieces.length > 0">Minus pri količini pomeni da je takšnih kosov preveč</p>
                    </b-col>

                    <b-table v-if="missingOrderPieces.length > 0" :items="missingOrderPieces" :fields="[{key: 'order_id', label: 'Naročilo', sortable: false, class: 'text-center'},
                                                                                                        {key: 'quantity', label: 'Količina', sortable: false, class: 'text-center'},
                                                                                                        {key: 'length', label: 'Dolžina', sortable: false, class: 'text-center'},]"></b-table>


                </b-row>
                <b-row align-h="end">
                    <b-button class="float-right mt-1 mr-1" variant="danger"
                              :disabled="plan.material_plans[selectedMaterialPlan].status === 'Confirmed' || !planLockedByThisUser"
                              @click="plan.material_plans[selectedMaterialPlan].stock_with_cuts_deduped = plan.material_plans[selectedMaterialPlan].original_stock_with_cuts">
                        Resetiraj
                    </b-button>
                    <b-button class="float-right mt-1 mr-3" variant="success"
                              :disabled="plan.material_plans[selectedMaterialPlan].status === 'Confirmed' || missingOrderPieces.length > 0 || !planLockedByThisUser"
                              @click="saveMaterialPlanConfirmation()">Shrani
                    </b-button>
                </b-row>
            </b-container>
        </b-modal>


        <b-modal size="m" v-model="showForceUnlockPlanModal" title="Prisilno odkleni plan" centered hide-footer>
            <h4>Prisilno odkleni plan {{title}} ?</h4>
            <h5>Trenutno ga je zaklenil uporabnik {{lock_info.name}} {{lock_info.surname}}</h5>
            <b-button class="float-right mt-1" variant="danger" @click="forceUnlockPlan()">Prisilno odkleni plan</b-button>
        </b-modal>


    </b-overlay>
</template>

<script>
    import OrderModal from '@/views/Narocilo'
    import NarocilaSelectable from '@/views/NarocilaSelectable'
    import fuzzysort from 'fuzzysort'
    import md5 from 'uuid/dist/esm-browser/md5'

    export default {
        components: {
            OrderModal,
            NarocilaSelectable
        },
        data() {
            return {
                materialOptions: [],
                materialOptionsAll: [],
                materialOptionsForChange: [],
                suggestedOrderDays: 4,
                suggestedOrderDaysMin: 2,
                selectedMaterialPlan: -1,
                loading: false,
                addOrderModal: false,
                suggestOrdersModal: false,
                editPlanAreaModal: false,
                orderCommentModal: false,
                lock_info: { id: 'temp', role: 'temp'},
                lock_refresh_interval: null,
                showConfirmPlanModal: false,
                orderNoteModal: false,
                orderMoveModal: false,
                planToConfirm: '',
                showConfirmMaterialPlanModal: false,
                showForceUnlockPlanModal: false,
                materialPlanConfirmationDetails: false,
                commentModal: false,
                selected_order: {comment_from_razrez: '', comment: ''},
                selectedOrderId: '',
                selectedOtherPlan: '',
                selectedOtherPlanRaw: {},
                orders: [],
                search: '',
                plan: {orders: [], material_plans: [], planned_amount: 0, comment: ''},
                otherPlans: [],
                perPage: 10,
                pageOptions: [10, 20, 50, 100],
                currentPage: 1,
                searchMaterial: '',
                perPageMaterial: 10,
                currentPageMaterial: 1,
                totalRowsMaterial: 1,
                selected: {},
                fields: [
                    {key: 'id', label: 'ID', sortable: true, class: 'text-center'},
                    {key: 'client', label: 'Stranka', sortable: true, class: 'text-center'},
                    {key: 'date_due', label: 'Zapadlost', sortable: true, class: 'text-center'},
                    {key: 'material_type.material', label: 'Material', sortable: true, class: 'text-center'},
                    {key: 'sales_man_code', label: 'Tržnik', sortable: true, class: 'text-center'},
                    {key: 'quantity', label: 'Količina', sortable: true, class: 'text-center'},
                    {key: 'area', label: 'Površina', sortable: true, class: 'text-center'},
                    {key: 'comment', label: 'Opomba', sortable: true, class: 'text-center'},
                    {key: 'optimization_status', label: 'Status optimizacije', sortable: true, class: 'text-center'},
                    {key: 'actions', label: ''}
                ],
                fieldsMaterial: [
                    {key: 'dolzina', label: 'Dolžina', sortable: true, sortDirection: 'desc', class: 'text-center'},
                    {key: 'items', label: 'Razrez', sortable: true, class: 'text-center'}
                    // {key: 'actions', label: '', class: 'text-center'}
                ],
                filters: {id: '', client: '', date_added: '', date_due: '', quantity: '', status: ''}
            }
        },
        methods: {
            md5,
            getPlanLockInformation() {
                const thisIns = this
                this.$http.get(`/api/v1/user/plan_locking/${this.$route.params.planId}`)
                    .then(function(response) {
                        thisIns.lock_info = response.data
                        thisIns.loading = false
                    }).catch(function(error) {
                        thisIns.loading = false
                        thisIns.$printWarning(`Napaka!: ${error.response.data.error}`)
                    })

            },
            lockPlan() {
                const thisIns = this
                this.$http.post(`/api/v1/user/plan_locking/${this.$route.params.planId}`)
                    .then(function(response) {
                        thisIns.lock_info = response.data
                        thisIns.loadData()
                        thisIns.loading = false
                        thisIns.$printSuccess('Plan zaklenjen!')
                    }).catch(function(error) {
                        thisIns.loading = false
                        thisIns.$printWarning(`Napaka!: ${error.response.data.error}`)
                    })

            },
            async unlockPlanWithSave() {
                await this.savePlan()
                const thisIns = this
                this.$http.delete(`/api/v1/user/plan_locking/${this.$route.params.planId}`)
                    .then(function(response) {
                        thisIns.lock_info = response.data
                        thisIns.loadData()
                        thisIns.loading = false
                        thisIns.$printSuccess('Plan odklenjen!')
                    }).catch(function(error) {
                        thisIns.loading = false
                        thisIns.$printWarning(`Napaka!: ${error.response.data.error}`)
                    })

            },
            forceUnlockPlan() {
                const thisIns = this
                this.$http.delete(`/api/v1/user/plan_locking/${this.$route.params.planId}/force`)
                    .then(function(response) {
                        thisIns.lock_info = response.data
                        thisIns.loadData()
                        thisIns.loading = false
                        thisIns.showForceUnlockPlanModal = false
                        thisIns.$printSuccess('Plan prisilno odklenjen!')
                    }).catch(function(error) {
                        thisIns.loading = false
                        thisIns.$printWarning(`Napaka!: ${error.response.data.error}`)
                    })

            },
            confirmMaterialPlan(materialId) {

                const thisIns = this
                this.$http.post(`/api/v1/user/plan/${this.$route.params.planId}/material/${materialId}/confirm`)
                    .then(function(response) {
                        thisIns.$printSuccess('Plan razreza za material uspešno potrjen!')
                        thisIns.plan = response.data
                        thisIns.loading = false
                    }).catch(function(error) {
                        thisIns.loading = false
                        thisIns.$printWarning(`Napaka!: ${error.response.data.error}`)
                    }).finally(() => {
                        thisIns.showConfirmMaterialPlanModal = false
                    })

            },
            confirmMaterialPlanAdmin(materialId) {
                const thisIns = this
                this.$http.post(`/api/v1/user/plan/${this.$route.params.planId}/material/${materialId}/confirm_admin`)
                    .then(function(response) {
                        thisIns.$printSuccess('Plan razreza za material uspešno potrjen!')
                        thisIns.plan = response.data
                        thisIns.loading = false
                    }).catch(function(error) {
                        thisIns.loading = false
                        thisIns.$printWarning(`Napaka!: ${error.response.data.error}`)
                    }).finally(() => {
                        thisIns.showConfirmMaterialPlanModal = false
                    })

            },
            setPerPage(item) {
                this.perPage = item
            },
            suggestOrders() {

                this.loading = true
                const thisIns = this

                const suggestRequest = {
                    date: this.plan.date_planned,
                    days_from_date: Number(this.suggestedOrderDays),
                    days_from_date_min: Number(this.suggestedOrderDaysMin),
                    plan_id: this.$route.params.planId
                }
                this.$http.post('/api/v1/user/suggest_orders', suggestRequest).then(response => {
                    if (response.data !== null) {
                        thisIns.plan.orders = response.data
                    }
                    thisIns.loading = false
                    thisIns.suggestOrdersModal = false
                    thisIns.savePlan()
                }).catch(function(error) {
                    thisIns.loading = false
                    thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                })

                this.loading = false

            },
            addOrder() {
                const thisIns = this
                this.$http.post(`/api/v1/user/plan/${this.$route.params.planId}/orders`, this.orders.filter(order => order.selected === true).map(order => order.id))
                    .then(function(response) {
                        thisIns.plan = response.data
                        thisIns.totalRows = thisIns.plan.orders.length

                        thisIns.$http.get('/api/v1/user/orders_not_in_plans')
                            .then(function(response) {
                                thisIns.orders = response.data.filter(order => {
                                    return thisIns.plan.orders.find(narocilo => {
                                        return narocilo.id === order.id
                                    }) === undefined
                                })
                            }).catch(function(error) {
                                thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                            })
                        thisIns.addOrderModal = false
                        thisIns.$printSuccess('Uspešno dodano!')
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })

            },
            moveOrderToOtherPlanModal(orderId) {
                this.orderMoveModal = true
                this.selectedOrderId = orderId

            },
            moveOrderToWaitingList() {

                const thisIns = this
                this.$http.post(`/api/v1/user/waiting_list/${this.selectedOrderId}`)
                    .then(function() {
                        thisIns.$printSuccess('Naročilo dodano na čakalno listo')
                        thisIns.removeOrder(thisIns.selectedOrderId)
                        thisIns.orderMoveModal = false
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })
                this.orderMoveModal = false
            },
            moveOrderToOtherPlan(otherPlanID) {

                const thisIns = this
                this.$http.post('/api/v1/user/transfer_order', {
                    order_id: this.selectedOrderId,
                    plan_id: this.$route.params.planId,
                    new_plan_id: otherPlanID
                })
                    .then(function(response) {
                        thisIns.plan = response.data
                        if (thisIns.plan.orders === null) {
                            thisIns.plan.orders = []
                        }
                        thisIns.orderMoveModal = false
                        thisIns.$printSuccess('Naročilo uspešno premaknjeno!')

                        thisIns.$http.get('/api/v1/user/orders_not_in_plans')
                            .then(function(response) {
                                thisIns.orders = response.data.filter(order => {
                                    return thisIns.plan.orders.find(narocilo => {
                                        return narocilo.id === order.id
                                    }) === undefined
                                })
                            }).catch(function(error) {
                                thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                            })
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            saveOrderComment() {
                const thisIns = this
                this.$http.post(`/api/v1/user/plan/${this.$route.params.planId}/order/${this.selected_order.id}/comment`, {comment: this.selected_order.comment_from_razrez})
                    .then(function() {
                        thisIns.$printSuccess('Komentar uspešno shranjen!')
                        thisIns.loadData()
                        thisIns.orderCommentModal = false
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            removeOrder(id) {

                if (this.cannotModifyOrder(id)) {
                    return
                }

                const thisIns = this
                this.$http.post(`/api/v1/user/plan/${this.$route.params.planId}/orders/remove`, [id])
                    .then(function(response) {
                        thisIns.plan = response.data
                        if (thisIns.plan.orders === null) {
                            thisIns.plan.orders = []
                        }
                        thisIns.totalRows = thisIns.plan.orders.length
                        thisIns.$printSuccess('Naročilo uspešno odstranjeno!')

                        thisIns.$http.get('/api/v1/user/orders_not_in_plans')
                            .then(function(response) {
                                thisIns.orders = response.data.filter(order => {
                                    return thisIns.plan.orders.find(narocilo => {
                                        return narocilo.id === order.id
                                    }) === undefined
                                })
                            }).catch(function(error) {
                                thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                            })
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })


            },
            sort() {
                this.material = this.material.sort((a, b) => ((a.dolzina > b.dolzina) ? 1 : -1))
            },
            flatten(obj, prefix = [], current = {}) {
                if (typeof (obj) === 'object' && obj !== null) {
                    for (const key of Object.keys(obj)) {
                        this.flatten(obj[key], prefix.concat(key), current)
                    }
                } else {
                    current[prefix.join('.')] = obj
                }
                return current
            },
            loadData() {
                const thisIns = this
                this.$http.get(`/api/v1/user/plan/${this.$route.params.planId}`)
                    .then(function(response) {
                        thisIns.plan = response.data
                        thisIns.plan.material_plans = thisIns.plan.material_plans.filter(it => it.material_type.material !== '')
                        if (thisIns.plan.orders === null) {
                            thisIns.plan.orders = []
                        }
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
                this.$http.get('/api/v1/user/plan/')
                    .then(function(response) {

                        thisIns.otherPlans = response.data.filter(plan => plan.id !== thisIns.$route.params.planId && plan.status === 'odprt').map(plan => {
                            return {
                                value: plan,
                                text: new Date(plan.date_planned).toLocaleDateString('sl')
                            }
                        })
                        if (thisIns.otherPlans.length > 0) {
                            thisIns.selectedOtherPlanRaw = thisIns.otherPlans[0].value
                            thisIns.selectedOtherPlan = thisIns.otherPlans[0].value.id
                        }

                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
                this.$http.get('/api/v1/user/orders_not_in_plans')
                    .then(function(response) {
                        thisIns.orders = response.data.filter(order => {
                            return thisIns.plan.orders.find(narocilo => {
                                return narocilo.id === order.id
                            }) === undefined
                        })
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })

                this.$http.get('/api/v1/user/material')
                    .then(function(response) {
                        thisIns.materialOptions = response.data.map((mType) => ({
                            value: mType,
                            text: `${mType.id}-${mType.material}`
                        }))
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })

                this.$http.get('/api/v1/user/material/all')
                    .then(function(response) {
                        thisIns.materialOptionsAll = response.data
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })

                this.getPlanLockInformation()

            },
            savePlan() {
                this.editPlanAreaModal = false
                this.commentModal = false
                const thisIns = this
                this.plan.planned_amount = Number(this.plan.planned_amount)
                this.$http.patch(`/api/v1/user/plan/${this.$route.params.planId}`, this.plan)
                    .then(function() {
                        thisIns.$printSuccess('Plan uspešno shranjen!')
                        thisIns.loading = false
                    }).catch(function(error) {
                        thisIns.loading = false
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            confirmPlan() {
                this.plan.status = 'potrjen'
                const thisIns = this
                this.plan.planned_amount = Number(this.plan.planned_amount)
                this.$http.patch(`/api/v1/user/plan/${this.$route.params.planId}`, this.plan)
                    .then(function() {
                        thisIns.$printSuccess('Plan uspešno potrjen!')
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    }).finally(() => {
                        thisIns.showConfirmPlanModal = false
                    })
            },
            optimimizePlan() {
                this.loading = true
                const thisIns = this
                this.$http.post(`/api/v1/user/plan/${this.$route.params.planId}/optimize`)
                    .then(function(response) {
                        thisIns.$printSuccess('Plan uspešno optimiziran!')
                        thisIns.plan = response.data
                        thisIns.savePlan()
                        thisIns.loading = false
                    }).catch(function() {
                        thisIns.$printWarning('Napaka! Mogoče ni dovolj materiala za naročila')
                        thisIns.loading = false
                    })
            },
            saveMaterialPlanConfirmation() {


                this.savePlan()
                this.materialPlanConfirmationDetails = false


            },
            cannotModifyOrder(orderId) {

                if (this.plan.material_plans.length === 0) {
                    return false
                }

                try {
                    if (this.plan.material_plans.reduce((acc, mp) => {
                        if (acc === []) {
                            acc = mp.orders
                        } else {
                            acc = acc.concat(mp.orders)
                        }
                        return acc
                    }, []).find(o => o.id === orderId).status === 'Potrjen') {
                        return true
                    }
                } catch (e) {
                    return false
                }


                return false

            },
            updateMaterialChoices() {
                const thisIns = this
                this.$http.get(`/api/v1/user/material/matching/${this.plan.material_plans[this.selectedMaterialPlan].material_type.id}`)
                    .then(function(response) {
                        thisIns.materialOptionsForChange = response.data.map((mType) => ({
                            value: mType,
                            text: mType.material
                        }))
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            getLeftOverStyleForOrderPiecesAndLength(order_pieces, length) {
                const number = ((length - order_pieces.reduce((a, b) => a + (b.length * b.quantity), 0)) * 10000) / length
                if (number >= 0 && number <= 1000) {
                    return {'color': 'green', 'font-weight': 'bold'}
                } else if (number > 1000 && number < 2000) {
                    return {'color': 'orange', 'font-weight': 'bold'}
                } else {
                    return {'color': 'red', 'font-weight': 'bold'}
                }

            },
            getLeftOverForOrderPiecesAndLength(order_pieces, length) {
                return length - order_pieces.reduce((a, b) => a + (b.length * b.quantity), 0)
            },
            async changeMaterial(orderId, newMaterialId) {
                const thisIns = this
                await this.$http.patch(`/api/v1/user/order/${orderId}/${newMaterialId}`)
                    .then(function() {
                        thisIns.$printSuccess('Material uspešno spremenjen!')
                        thisIns.savePlan()
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            }
        },
        computed: {
            planUnlocked() {
                return this.lock_info.id === '000000000000000000000000'
            },
            planLockedByThisUser() {
                return this.lock_info.id === this.$store.state.user.userData.id
            },
            planLockedByOtherUser() {
                return  this.lock_info.id && this.lock_info.id !== 'temp' && this.lock_info.id !== '000000000000000000000000' && this.lock_info.id !== this.$store.state.user.userData.id
            },
            loadingCombined() {
                return this.loading || this.$store.state.app.showLoader
            },
            buttonsDisabled() {
                return !(this.plan.status === 'odprt') || this.loading || this.planUnlocked || !this.planLockedByThisUser
            },
            addButtonsDisabled() {
                return this.$store.state.user.planning === false || this.loading || !(this.plan.status === 'odprt') || this.planUnlocked || !this.planLockedByThisUser
            },
            totalRows() {
                return this.filtered.length
            },
            filtered() {
                const filtered = this.plan.orders.filter(item => {
                    return Object.keys(this.filters).every(key => String(this.flatten(item)[key]).toLowerCase().includes(this.filters[key].toLowerCase()) || fuzzysort.single(this.filters[key].toLowerCase(), String(this.flatten(item)[key]).toLowerCase()) !== null)
                })
                return filtered.length > 0 ? filtered : [
                    {
                        id: '',
                        client: '',
                        date_added: '',
                        date_due: '',
                        quantity: '',
                        status: ''
                    }
                ]
            },
            title() {
                return `${new Date(this.plan.date_planned).toLocaleDateString('sl')} - ${this.plan.bcid}`
            },
            getCurrentOrdersArea() {
                try {
                    return this.plan.orders.filter(order => order.order_pieces !== undefined).reduce((acc, cur) => acc + cur.order_pieces.reduce((innerAcc, innerCur) => innerAcc + (innerCur.quantity * innerCur.length * innerCur.width), 0), 0) / 1000000
                } catch (e) {
                    return 0
                }

            },
            planningStatus() {
                return this.$store.state.user.planning
            },
            missingOrderPieces() {
                if (this.selectedMaterialPlan === null || this.plan.material_plans[this.selectedMaterialPlan] === undefined || this.plan.material_plans[this.selectedMaterialPlan].orders === undefined || this.plan.material_plans[this.selectedMaterialPlan].orders.length === 0) {
                    return []
                }
                const piecesInPlan = JSON.parse(JSON.stringify(this.plan.material_plans[this.selectedMaterialPlan].orders)).reduce((acc, cur) => {
                    if (acc === []) {
                        acc = cur.order_pieces
                    } else {
                        cur.order_pieces.forEach((piece) => {
                            const index = acc.findIndex((accPiece) => accPiece.order_id === piece.order_id && accPiece.length === piece.length)
                            if (index !== -1) {
                                acc[index].quantity += piece.quantity
                            } else {
                                acc.push(piece)
                            }
                        })

                    }
                    return acc
                }, [])

                let piecesOnStock = []
                try {

                    piecesOnStock = JSON.parse(JSON.stringify(this.plan.material_plans[this.selectedMaterialPlan].stock_with_cuts_deduped)).reduce((acc, cur) => {
                        if (acc === []) {
                            acc = cur.order_pieces
                        } else {
                            cur.order_pieces.forEach((piece) => {
                                const index = acc.findIndex((accPiece) => accPiece.order_id === piece.order_id && accPiece.length === piece.length)
                                if (index !== -1) {
                                    acc[index].quantity += (piece.quantity * cur.deduped_quantity)
                                } else {
                                    acc.push(piece)
                                    acc[acc.length - 1].quantity *= cur.deduped_quantity
                                }
                            })

                        }
                        return acc
                    }, [])
                } catch (e) {
                    piecesOnStock = []
                }


                return piecesInPlan.map((piece) => {
                    const index = piecesOnStock.findIndex((stockPiece) => stockPiece.order_id === piece.order_id && stockPiece.length === piece.length)
                    if (index !== -1) {
                        piece.quantity -= piecesOnStock[index].quantity
                    }
                    return piece
                }).filter((piece) => piece.quantity !== 0)
            }
        },
        watch: {
            planningStatus() {
                this.loadData()
            }
        },
        destroyed() {
            clearInterval(this.lock_refresh_interval)
        },
        created() {
            this.loadData()
            this.lock_refresh_interval = setInterval(() => {
                this.getPlanLockInformation()
            }, 3000)
        }
    }
</script>

<style scoped>
.tabela tr:first-child td:nth-child(1),
.tabela tr:first-child td:nth-child(2) {
  border: 1px transparent;
}
</style>
